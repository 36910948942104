import styled from "styled-components";

const Layout = styled.div`
  /* margin-top: 75px; */
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  overflow: hidden;
`;

export default Layout;
